import logoImageDesktop from '../assets/biketribe-logo-desktop.png';
import logoImageMobile from '../assets/biketribe-logo-mobile.png';
import brandImage from '../assets/biketribe-brandImage-1500.jpg';
import facebookImage from '../assets/biketribe-facebook-sharing-1200x630.jpg';
import twitterImage from '../assets/biketribe-twitter-sharing-600x314.jpg';
import signupImage from '../assets/signup.png';

import lenderListItemImage from '../assets/lender_list_item.png';
import lenderGetPaidImage from '../assets/lender_get_paid.png';
import lenderOfferImage from '../assets/lender_offers.png';

import renterRequestImage from '../assets/renter_request.png';
import renterSearchItemImage from '../assets/renter_search.png';
import renterCollectImage from '../assets/renter_collect.png';

import categoriesImage from '../assets/categories.png';
import ourStoryImage from '../assets/our_story.png';

import whyItMattersImage from '../assets/why_it_matters.png';
import lenderSectionImage from '../assets/lender_section.png';
import renterSectionImage from '../assets/renter_section.png';


import categoryChildImage from '../assets/categories/child.png';
import clothingChildImage from '../assets/categories/clothing.png';
import electronicsChildImage from '../assets/categories/electronics.png';
import homewareChildImage from '../assets/categories/homeware.png';
import outdoorEquipmentChildImage from '../assets/categories/outdoor_equipment.png';
import partyChildImage from '../assets/categories/party.png';
import sportsChildImage from '../assets/categories/sports.png';


import buildCommunityImage from '../assets/matters/build_community.png';
import saveMoreImage from '../assets/matters/save_more.png';
import supportSustainabilityImage from '../assets/matters/support.png';
import minimizeWasteImage from '../assets/matters/waste.png';


import startEarningImage from '../assets/start_earning.png';
import findAndRentImage from '../assets/conversation.png';


import securePaymentsImage from '../assets/explore/secure-payments-image.png';
import inAppMessagingImage from '../assets/explore/in-app-messaging-image.png';
import searchImage from '../assets/explore/search-image.png';
import quickPayoutImage from '../assets/explore/quick-payout-image.png';


import stripeInfoImage from '../assets/stripe_info.png';

import bgMobileImage from '../assets/bg-mobile.png';





/////////////////////////////////////////////////////////
// This file contains configs that affect branding     //
// NOTE: these are just some of the relevant configs   //
// Most of the work happens in marketplaceDefaults.css //
// and in components.                                  //
/////////////////////////////////////////////////////////

// Note: These come from the branding asset nowadays by default.
//       To use this built-in configuration, you need to remove the overwrite from configHelper.js (mergeBranding func)

// Marketplace color.
// This is saved as CSS Property: --marketplaceColor in src/app.js
// Also --marketplaceColorDark and --marketplaceColorLight are generated from this one
// by adding +/- 10% to lightness.
export const marketplaceColor = '#7c3aed';

// Logo is used in Topbar on mobile and desktop, where height is the limiting factor.
// Therefore, we strongly suggest that your image file for desktop logo is in landscape!
//
// If you need to fine-tune the logo, the component is defined in src/components/Logo/Logo.js
// By default logo gets 24 pixels vertical space, but it could be wider (e.g. 180px)
// The default images are meant for retina displays and are therefore twice as big in actual dimensions
export const logoImageDesktopURL = logoImageDesktop;
export const logoImageMobileURL = logoImageMobile;

// The _logoSettings_ settings for the logo. Due to constraints in current Topbar, we only support 3 height variants
// at this point. There could be more height variants in the future and potentially other logo formats than 'image'.
// Note: logo image is always scaled and the limiting factor is height. However, there's also maximum width,
//       which is 370px in the Topbar. If the logo is wider than that, browser will scale it down.
export const logoSettings = {
  height: 24, // Hosted asset supports: 24, 36, 48
  format: 'image',
};

// brandImageURL is used as a background image on the "hero" section of several pages.
// Used on AuthenticationPage, EmailVerificationPage, PasswordRecoveryPage, PasswordResetPage etc.
// NOTE: Those pages use ResponsiveBackgroundImageContainer component,
//       it's possible to include more image variants to make image fetching more performant.
export const brandImageURL = brandImage;

// Default images for social media sharing
// These can be overwritten per page

// For Facebook, the aspect ratio should be 1200x630 (otherwise, the image is cropped)
export const facebookImageURL = facebookImage;
// For Twitter, the aspect ratio should be 600x314 (otherwise, the image is cropped)
export const twitterImageURL = twitterImage;
export const signupImageURL = signupImage;

export const lenderListItemImageURL = lenderListItemImage;
export const lenderOfferImageURL = lenderOfferImage;
export const lenderGetPaidImageURL = lenderGetPaidImage;

export const renterRequestImageURL = renterRequestImage;
export const renterSearchItemImageURL = renterSearchItemImage;
export const renterCollectImageURL = renterCollectImage;

export const categoriesImageURL = categoriesImage;
export const ourStoryImageURL = ourStoryImage;

export const whyItMattersImageURL = whyItMattersImage;
export const lenderSectionImageURL = lenderSectionImage;
export const renterSectionImageURL = renterSectionImage;

export const categoryChildImageURL = categoryChildImage;
export const clothingChildImageURL = clothingChildImage;
export const electronicsChildImageURL = electronicsChildImage;
export const homewareChildImageURL = homewareChildImage;
export const outdoorEquipmentChildImageURL = outdoorEquipmentChildImage;
export const partyImageURL = partyChildImage;
export const sportsChildImageURL = sportsChildImage;



export const buildCommunityImageURL = buildCommunityImage;
export const saveMoreImageURL = saveMoreImage;
export const supportSustainabilityImageURL = supportSustainabilityImage;
export const minimizeWasteImageURL = minimizeWasteImage;

export const startEarningImageURL = startEarningImage;
export const findAndRentImageURL = findAndRentImage;


export const securePaymentsImageURL = securePaymentsImage;
export const inAppMessagingImageURL = inAppMessagingImage;
export const searchImageURL = searchImage;
export const quickPayoutImageURL = quickPayoutImage;


export const stripeInfoImageURL = stripeInfoImage;

export const bgMobileImageURL = bgMobileImage;





